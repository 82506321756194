import { getDefaultUser } from "./profile";
import getElementById from "./guarded-getElementById";
import { onLoad } from "./onload";

onLoad(() => {
  getDefaultUser()
    .then((user) => {
      if (user.uid) {
        getElementById('notLoggedIn').then(elem => elem.classList.add('hidden'));
        getElementById('loggedIn').then(elem => elem.classList.remove('hidden'));
      }
    })
})